import { Page } from '../pages/pages.model';
import { API_STATUS, BUSINESS_UNITS, ENVS } from '../utils/constants';
import {
  Environments,
  HOME_IMPROVEMENT_SERVICE_BASE_URL,
} from '../utils/constants/urls';
import { AppDataModel } from '../providers/app-data/app-data.model';
import {
  getContentsToInclude,
  getCsEntryId,
  getCurrentStore,
  getCurrentTenant,
} from '../utils';
import { PAGE_NAMES } from '../pages/page-wrapper/constants';

export const getTheme = (tenant: string) => {
  const lowerCaseTenant = tenant.toLowerCase();
  const isSodimac = lowerCaseTenant.includes('so');
  if (isSodimac) {
    return BUSINESS_UNITS.SODIMAC;
  } else {
    return BUSINESS_UNITS.FALABELLA;
  }
};

export const getQueryParams = () => {
  const tenant = getCurrentTenant().toLowerCase();
  const csEntryId = getCsEntryId();
  const contentsToInclude = getContentsToInclude();
  const store = getCurrentStore();

  return {
    tenant,
    csEntryId,
    contentsToInclude,
    store,
  };
};

export const getAppConfigUrl = (
  entryId: string,
  tenant: string,
  contentsToInclude: string,
  store: string
): string => {
  let env = process.env.REACT_APP_ENVIRONMENT as Environments;
  if (ENVS.includes(env) === false) {
    env = 'production';
  }
  const country = tenant.substring(0, 2).toUpperCase() as 'SO' | 'FA';
  const url = new URL(HOME_IMPROVEMENT_SERVICE_BASE_URL[country][env]);
  const includeString = `[${JSON.stringify(contentsToInclude)}]`;

  url.pathname = `/s/home-improvements/services/content/configuration/${entryId}/${tenant}`;
  url.searchParams.append('store', store);
  url.searchParams.append('include', includeString);

  return url.toString();
};

export const sortPages = (pages: Page[] = []) => {
  const sortedPages = [...pages].sort((a: any, b: any) => {
    return b.isStandalonePage - a.isStandalonePage;
  });
  return sortedPages;
};

export const getStandAlonePagesCount = (pages: Page[]) => {
  return pages.filter((page: Page) => page.isStandalonePage).length;
};

export const getStepperData = (pages: Page[]) => {
  const stepperPages = pages.filter((page: Page) => !page.isStandalonePage);
  const stepperInfo: {
    mobileTitles: string[];
    desktopTitles: string[];
  } = {
    mobileTitles: [],
    desktopTitles: [],
  };
  stepperPages.forEach((page: Page) => {
    stepperInfo.mobileTitles.push(page.stepperTitleMobile || '');
    stepperInfo.desktopTitles.push(page.stepperTitleDesktop || '');
  });

  return stepperInfo;
};

const isProductDetailsPageAvailable = (pages: Page[]) => {
  return pages.some(
    (page: Page) =>
      page.contentTypeUid === PAGE_NAMES.JOURNEYS_PAGE_PRODUCT_DETAILS
  );
};

export const fetchAppData = async (): Promise<Partial<AppDataModel>> => {
  const { tenant, csEntryId, contentsToInclude, store } = getQueryParams();

  const url = getAppConfigUrl(csEntryId, tenant, contentsToInclude, store);
  const pageData = await fetch(url);
  const pageDataJson = await pageData.json();
  const sortedPages = sortPages(pageDataJson.pages || []);
  const standalonePagesCount = getStandAlonePagesCount(sortedPages);
  const deviceSpecificStepperTitles = getStepperData(sortedPages);
  const stepperData = {
    currentStep: 0,
    totalSteps: sortedPages.length,
    standalonePagesCount,
    ...deviceSpecificStepperTitles,
  };
  const { categoryId = '' } = pageDataJson;
  const appData = {
    pages: sortedPages,
    stepper: stepperData,
    status: API_STATUS.SUCCESS,
    isProductDetailsPageAvailable: isProductDetailsPageAvailable(
      pageDataJson.pages || []
    ),
    categoryId,
  };

  return appData;
};
