import { noop } from '../../utils';
import { API_STATUS } from '../../utils/constants';
import { AppDataModel } from './app-data.model';
import { initialPageData } from './initial-response';

export const initialState: AppDataModel = {
  pages: initialPageData.pages,
  stepper: {
    currentStep: 1,
    totalSteps: 5,
    standalonePagesCount: 1,
    mobileTitles: [],
    desktopTitles: [],
  },
  status: API_STATUS.IDLE,
  categoryId: '',
  isProductDetailsPageAvailable: false,
  filteredProducts: [
    {
      productId: '217220',
      variantId: '217220',
    },
  ],
};

export const initialAppContext = {
  appState: initialState,
  appActions: {
    updateAppData: noop,
    updateStepperInfo: noop,
  },
};
