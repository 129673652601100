export const BUSINESS_UNITS = {
  SODIMAC: 'sodimac' as const,
  FALABELLA: 'falabella' as const,
};

export const API_STATUS = {
  IDLE: 'idle' as const,
  LOADING: 'loading' as const,
  SUCCESS: 'success' as const,
  ERROR: 'error' as const,
};

export const ENVS = ['development', 'staging', 'beta', 'production'] as const;

export const POST_MESSAGE_TYPES = {
  SET_IFRAME_HEIGHT: 'setIframeHeight' as const,
};

export const DEFAULT_IFRAME_HEIGHT = 800 as const;
export const DEFAULT_NEXT_BUTTON_TEXT = 'Continuar' as const;
export const DEFAULT_BACK_BUTTON_TEXT = 'Volver' as const;
export const INPUT_TYPES = {
  NUMBER: 'number' as const,
  DECIMAL: 'decimal' as const,
};
export const INPUT_MODES = {
  [INPUT_TYPES.NUMBER]: 'numeric' as const,
  [INPUT_TYPES.DECIMAL]: 'numeric' as const,
};

export const FACET_ATTRIBUTE_PREFIX = {
  FA: 'product.attribute.',
  SO: '',
  // TODO
};

export const VIEW_TYPES = {
  LIST: 'List' as const,
  GRID: 'Grid' as const,
};

export const CLASSES_LIST = {
  ACTIVE: 'active' as const,
  CURRENT: 'current' as const,
};

export const ADD_TO_CART_MODAL_DATA = {
  SUCCESS_TITLE: 'Producto agregado al carro',
  FAILED_TITLE: '¡Ups! Algo salió mal',
  KEEP_BUYING_BUTTON_TEXT: 'Seguir comprando',
  GO_TO_CART_BUTTON_TEXT: 'Ir al carrito',
};

export const DEAFULT_ADD_TO_CART_BUTTON_TEXT = 'Agregar al carro';
export const PRICE_INFO_CONTAINER_TITLE = 'Resumen de compra';

export const PRICE_TYPES = {
  NORMAL: 'NORMAL' as const,
};

export const BUTTON_POSITIONS = {
  LEFT: 'left' as const,
  RIGHT: 'right' as const,
};

export const SODIMAC_COOKIES = {
  PG_ID_CO: 'usrLocation',
  PG_ID: 'usrStore',
  ZONES: 'comuna',
};

export const FALABELLA_COOKIES = {
  ZONES: 'zones',
  PG_ID: 'priceGroupId', // TODO: confirm
  PID: 'politicalId', // TODO: confirm
};

export const APP_URL_QUERY_PARAMS = {
  TENANT: 'tenant',
  IS_MOBILE_APP: 'isMobileApp',
  ZONES: 'zones',
  PG_ID: 'pgId',
  PID: 'pId',
  CS_ENTRY_ID: 'csEntryId',
  IS_ATG_ENABLED: 'isAtgEnabled',
  CONTENTS_TO_INCLUDE: 'contentsToInclude',
  STORE: 'store',
  // TODO: ADD MORE
};

export const MOBILE_APP_EVENTS = {
  ADD_TO_CART: 'addToCart',
};
