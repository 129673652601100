import { Input } from '@digital-retail/journey-ui-kit';
import useDevice from '../../hooks/useDevice';
import { Page } from '../pages.model';
import { MeasurementPage } from './measurement.model';
import {
  InfoContainer,
  InfoText,
  InfoTitle,
  InputWrapper,
  InputsContainer,
  LabelContainer,
  LabelIcon,
  LabelText,
  PageColumn1,
  PageContainer,
  PageContent,
  PageImage,
  PageTitle,
} from './styles';
import useMeasurement from './useMeasurement';
import { INPUT_MODES } from '../../utils/constants';
import NextAndBackButtons from '../../components/next-and-back-buttons';

const Measurement: React.FC<{ data: Page }> = ({ data }) => {
  const pageData = data as MeasurementPage;
  const {
    pageTitle,
    nextButtonText,
    isNextButtonDisabled,
    backButtonText,
    imageUrl,
    imageTitle,
    noteTitle,
    noteDescription,
    inputsData,
  } = useMeasurement(pageData);
  const { isMobile } = useDevice();

  return (
    <PageContainer>
      <PageTitle>{pageTitle}</PageTitle>
      <PageContent>
        <PageColumn1>
          <InputsContainer>
            {inputsData.map((inputData) => {
              return (
                <InputWrapper key={inputData.label}>
                  <LabelContainer>
                    <LabelIcon src={inputData.labelIcon} />
                    <LabelText>{inputData.label}</LabelText>
                  </LabelContainer>
                  <Input
                    value={inputData.value}
                    error={false}
                    placeholder={inputData.placeholderValue}
                    type={inputData.type}
                    inputMode={INPUT_MODES[inputData.type]}
                    onChange={(value) =>
                      inputData.onChange(inputData.attributeName, value)
                    }
                  />
                </InputWrapper>
              );
            })}
          </InputsContainer>
          {!isMobile && (
            <NextAndBackButtons
              dataTestId="next-and-back-buttons-desktop"
              nextButtonText={nextButtonText}
              backButtonText={backButtonText}
              isDisabled={isNextButtonDisabled}
            />
          )}
        </PageColumn1>
        <PageImage src={imageUrl} alt={imageTitle}></PageImage>
        <InfoContainer>
          <InfoTitle>{noteTitle}</InfoTitle>
          <InfoText>{noteDescription}</InfoText>
        </InfoContainer>
        {isMobile && (
          <NextAndBackButtons
            dataTestId="next-and-back-buttons-mobile"
            nextButtonText={nextButtonText}
            backButtonText={backButtonText}
            isDisabled={isNextButtonDisabled}
          />
        )}
      </PageContent>
    </PageContainer>
  );
};

export default Measurement;
