import { useContext } from 'react';
import { updateImageUrlHost } from '../../utils';
import {
  DEFAULT_BACK_BUTTON_TEXT,
  DEFAULT_NEXT_BUTTON_TEXT,
  INPUT_TYPES,
} from '../../utils/constants';
import { MeasurementPage } from './measurement.model';
import { MeasurementDataContext } from '../../providers/measurement';
import { IS_NEXT_BUTTON_DISABLED } from './constants';
import { MeasurementDataModel } from '../../providers/measurement/measurement-state.model';

const useMeasurement = (data: MeasurementPage) => {
  const { measurementState, updateMeasurementData } = useContext(
    MeasurementDataContext
  );

  const {
    title = '',
    pageTitle = '',
    noteTitle = '',
    noteDescription = '',
    nextButtonText = DEFAULT_NEXT_BUTTON_TEXT,
    backButtonText = DEFAULT_BACK_BUTTON_TEXT,
    imageDesktop = { url: '', title: '' },
    inputFields = [],
  } = data;

  const imageUrl = updateImageUrlHost(imageDesktop.url) || '';
  const imageTitle = imageDesktop.title;

  const getButtonStatus = (updatedState: MeasurementDataModel) => {
    for (const key in updatedState) {
      if (key !== IS_NEXT_BUTTON_DISABLED) {
        const value = updatedState[key];
        if (!value) {
          return true;
        }
      }
    }
    return false;
  };

  const onInputValueChange = (attributeName: string, value: string) => {
    const updatedState = {
      ...measurementState,
      [attributeName]: value,
    };
    const isNextButtonDisabled = getButtonStatus(updatedState);
    const updatedMeasurementState = {
      ...updatedState,
      isNextButtonDisabled,
    };
    updateMeasurementData(updatedMeasurementState);
  };

  const parseInputsData = (inputsData: MeasurementPage['inputFields']) => {
    const inputFields = inputsData.map((inputData) => {
      const {
        labelIcon = { url: '' },
        label = '',
        placeholderValue = '',
        type = INPUT_TYPES.NUMBER,
        attributeName = '',
      } = inputData;
      return {
        labelIcon: updateImageUrlHost(labelIcon.url) || '',
        label,
        placeholderValue,
        type,
        attributeName,
        value: (measurementState[attributeName] as string) || '',
        onChange: onInputValueChange,
      };
    });
    return inputFields;
  };
  const parsedInputsData = parseInputsData(inputFields);

  return {
    title,
    pageTitle,
    noteTitle,
    noteDescription,
    nextButtonText,
    backButtonText,
    imageUrl,
    imageTitle,
    inputsData: parsedInputsData,
    ...measurementState,
  };
};

export default useMeasurement;
