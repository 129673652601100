import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';

export const PageButton = styled.div`
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  font-size: ${FONT_SIZES.STANDARD};
  padding: 9px 32px;
  cursor: pointer;

  &.filled {
    border-radius: 28px;
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
  }

  &.ghost {
    // background-color: ${COLORS.WHITE};
    color: ${COLORS.PRIMARY};
    pointer-events: none;
    align-items: center;
    padding-left: 0;
    padding-right: 0;

    span {
      margin-right: 7px;
    }

    span,
    img {
      pointer-events: all;
      cursor: pointer;
    }
  }

  &.ghost&.left {
    span {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  &.disabled {
    background-color: ${COLORS.GRAY_300};
    color: ${COLORS.NEUTRAL_GRAY_80};
    pointer-events: none;
  }
`;

export const ButtonIcon = styled.img`
  position: relative;
  top: 5px;

  &.left {
    transform: rotate(270deg);
  }
`;
