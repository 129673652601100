import { noop } from '../../utils';
import { MeasurementDataModel } from './measurement-state.model';

export const MEASUREMENT_DATA_INITIAL_STATE: MeasurementDataModel = {
  ancho: '',
  alto: '',
  isNextButtonDisabled: true,
  selectedOptions: {
    Medidas: '130 cm x 180 cm',
  },
};

export const initialMeasurementContext = {
  measurementState: MEASUREMENT_DATA_INITIAL_STATE,
  updateMeasurementData: noop,
  resetMeasurementData: noop,
};
