/* istanbul ignore file */
import {
  getCurrentAppEnvironment,
  getCurrentTenant,
  getMobileAppStatus,
  getProductDetailsApiQueryParams,
  isAddToCartAtgEnabled,
  isSodimacBusinessUnit,
} from '../utils';
import {
  ATG_CART_URL,
  ATG_SITE_IDS,
  FETCH_SESSION_INFO_URL,
  HOME_IMPROVEMENT_SERVICE_BASE_URL,
  PRODUCT_DETAILS_API_QUERY_PARAMS,
  PRODUCT_DETAILS_URL,
  TENANTS,
} from '../utils/constants/urls';
import {
  AddToCartRequestBody,
  AddToCartResponse,
  ProductDetailsResponse,
  SessionInfoResponse,
} from './models/addtocart.service.model';
import { AddToCartPageState } from '../providers/addtocart/addtocart-data.model';
import { MOBILE_APP_EVENTS, PRICE_TYPES } from '../utils/constants';

export const getFetchSessionInfoUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const url = `${baseUrl}/${FETCH_SESSION_INFO_URL}`;

  return url;
};

export const getAddToCartAtgUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const siteId = ATG_SITE_IDS[tenant];
  const url = `${baseUrl}/${ATG_CART_URL}?pushSite=${siteId}`;

  return url;
};

export const atgAddToCart = async (
  productsData: AddToCartRequestBody['formSubmissionData']
) => {
  try {
    const fetchSessionInfoUrl = getFetchSessionInfoUrl();
    const sessionData = await fetch(fetchSessionInfoUrl);
    const sessionDataJson = (await sessionData.json()) as SessionInfoResponse;
    const {
      state: { sessionConfirmationNumber = '' },
      success,
    } = sessionDataJson;

    if (success) {
      const addToCartAtgUrl = getAddToCartAtgUrl();
      const addToCartRequestBody: AddToCartRequestBody = {
        sessionConfirmationNumber,
        formSubmissionData: productsData,
      };
      const addToCartAtgResponse = await fetch(addToCartAtgUrl, {
        method: 'POST',
        headers: {},
        body: JSON.stringify(addToCartRequestBody),
      });
      const addToCartAtgResponseJson =
        (await addToCartAtgResponse.json()) as AddToCartResponse;

      return addToCartAtgResponseJson;
    }

    return { success: false };
  } catch (error) {
    return { success: false };
  }
};

export const addToCart = async (
  productsData: AddToCartRequestBody['formSubmissionData']
) => {
  let res = { success: false };
  try {
    const isMobileApp = getMobileAppStatus();
    const isAtgEnabled = isAddToCartAtgEnabled();
    const isSodimacBU = isSodimacBusinessUnit();

    if (isMobileApp) {
      window.parent.dispatchEvent(
        new CustomEvent(MOBILE_APP_EVENTS.ADD_TO_CART, {
          detail: {
            productsData,
          },
        })
      );
    } else if (isAtgEnabled) {
      res = await atgAddToCart(productsData);
    } else if (isSodimacBU) {
      // handle sodimac add to cart
    } else {
      // handle falabella add to cart
    }
  } catch (error) {
    // TODO: handle error case
  }

  return res || { success: false };
};

export const getProductDetailsUrl = (productId: string, variantId: string) => {
  const tenant = getCurrentTenant();
  const businessUnit = tenant.substring(0, 2);
  const env = getCurrentAppEnvironment();
  const baseUrl = HOME_IMPROVEMENT_SERVICE_BASE_URL[businessUnit][env];
  const lowercaseTenant = tenant.toLowerCase();
  const url = new URL(`${baseUrl}/${PRODUCT_DETAILS_URL}/${lowercaseTenant}`);
  const { PRODUCT_ID, VARIANT_ID, ZONES, PGID, PID } =
    PRODUCT_DETAILS_API_QUERY_PARAMS;
  const { zones, pgId, pId } = getProductDetailsApiQueryParams();
  url.searchParams.append(PRODUCT_ID, productId);
  url.searchParams.append(VARIANT_ID, variantId);
  if (zones) {
    url.searchParams.append(ZONES, zones);
  }
  if (pgId) {
    url.searchParams.append(PGID, pgId);
  }
  if (pId) {
    url.searchParams.append(PID, pId);
  }

  return url.toString();
};

export const getPriceInfo = (
  prices: ProductDetailsResponse['variant']['prices'],
  serviceOptions: ProductDetailsResponse['serviceOptions'],
  productPriceLabel: string
) => {
  const normalPriceData = prices.find((price) => {
    const { type = '' } = price;
    const upperCaseType = type.toUpperCase();
    return upperCaseType === PRICE_TYPES.NORMAL;
  });
  const { price: normalPriceValue = '', symbol = '$' } = normalPriceData || {};
  const finalNormalPriceValue =
    typeof normalPriceValue === 'string'
      ? normalPriceValue
      : normalPriceValue[0];
  const normalPrice = `${symbol}${finalNormalPriceValue}`;
  let priceInfo = {
    [productPriceLabel]: normalPrice,
  };

  const installationPriceValue = serviceOptions?.price?.price || '';
  const installationPrice = `${symbol}${installationPriceValue}`;
  if (installationPriceValue) {
    priceInfo = {
      ...priceInfo,
      Instalación: installationPrice,
    };
  }

  return priceInfo;
};

const getTotalPrice = (
  priceInfo: AddToCartPageState['priceInfo'],
  priceConfig: ProductDetailsResponse['priceConfig']
) => {
  const { decimalSeparator, thousandSeparator, precision, currencySymbol } =
    priceConfig;
  const allPrices = Object.values(priceInfo);
  const allUnFormattedPrices = allPrices.map((price) => {
    const unFormattedPriceArray = [];
    for (let i = 0; i < price.length; i++) {
      const char = price[i];
      if (char === currencySymbol) {
        unFormattedPriceArray.push('');
      } else if (char === thousandSeparator) {
        unFormattedPriceArray.push('');
      } else if (char === decimalSeparator) {
        unFormattedPriceArray.push('.');
      } else {
        unFormattedPriceArray.push(char);
      }
    }
    const unFormattedPrice = unFormattedPriceArray.join('');

    return Number(unFormattedPrice);
  });

  const totalPrice = allUnFormattedPrices.reduce((a, b) => a + b, 0);
  const finalTotalPrice = totalPrice.toFixed(precision);
  const [integerValue, decimalvalue] = finalTotalPrice.split('.');
  const formattedIntegerValue = integerValue
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
    .trim();
  const formattedTotalPrice = `${currencySymbol}${formattedIntegerValue}${decimalSeparator}${decimalvalue}`;

  return formattedTotalPrice;
};

export const parseProductDetailsData = (
  data: ProductDetailsResponse,
  productPriceLabel: string
): AddToCartPageState => {
  const {
    name,
    brandName,
    variant: { images = [], prices = [] },
    priceConfig,
    serviceOptions,
  } = data;

  const priceInfo = getPriceInfo(prices, serviceOptions, productPriceLabel);
  const totalPrice = getTotalPrice(priceInfo, priceConfig);
  const serviceSkuId = serviceOptions?.id || '';

  const parsedData = {
    brandName,
    productCode: '',
    productTitle: name,
    priceInfo,
    totalPrice,
    images: images,
    serviceSkuId,
  };

  return parsedData;
};

export const getProductDetails = async (
  productId: string,
  variantId: string,
  productPriceLabel: string
) => {
  let finalResponse = {
    success: false,
    productDetails: {} as AddToCartPageState,
  };
  try {
    const url = getProductDetailsUrl(productId, variantId);
    const productDetails = await fetch(url);
    const productDetailsJson =
      (await productDetails.json()) as ProductDetailsResponse;
    const parsedData = parseProductDetailsData(
      productDetailsJson,
      productPriceLabel
    );

    finalResponse = { success: true, productDetails: parsedData };
  } catch (error) {
    // TODO: handle error case
  }

  return finalResponse;
};
