export const initialPageData = {
  pages: [
    {
      contentTypeUid: 'journeys_page_introduction',
      backButtonText: '',
      cards: [
        {
          title: 'Personaliza tu ventana',
          description:
            '<p>Selecciona las características y las medidas de tu ventana.</p>',
          imageDesktop: {
            contentType: 'image/jpeg',
            filename: 'page_1_card_1.jpeg',
            title: 'page_1_card_1.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt58393265938c11e2/653f44c626f138001808e669/page_1_card_1.jpeg',
          },
        },
        {
          title: 'Opciones de instalación',
          description:
            '<p>Si incluyes la instalación, te contactaremos en <strong>48 hrs hábiles</strong> para coordinar una visita.</p>',
          imageDesktop: {
            contentType: 'image/png',
            filename: 'page_2_card_2.png',
            title: 'page_2_card_2.png',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt5441166b35da77f9/653f44d826f138001808e66d/page_2_card_2.png',
          },
        },
        {
          title: 'Agrega al Carro',
          description:
            '<p>Revisa tu pedido y agrega al Carro. Fabricaremos y enviaremos tu ventana lo antes posible.</p>',
          imageDesktop: {
            contentType: 'image/jpeg',
            filename: 'page-1-card-3.jpeg',
            title: 'page-1-card-3.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltcced3d45255b0a98/653f44e6039e2c00189df1ce/page-1-card-3.jpeg',
          },
        },
      ],
      isStandalonePage: true,
      nextButtonText: '¡Empecemos!',
      pageTitle:
        '<p>Compra <strong>ventanas a medida</strong></p><p>Simplificamos el proceso de <strong>elección de ventanas a medida</strong> con instrucciones sencillas y pasos fáciles de seguir.</p>',
      showcaseImageDesktop: {
        contentType: 'image/jpeg',
        filename: 'page-1-showcase.jpeg',
        title: 'page-1-showcase.jpeg',
        url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltd99365fd3b5bffcf/65730970841cdfbba274cb93/page-1-showcase.jpeg',
      },
      stepperTitleDesktop: 'Introduction',
      stepperTitleMobile: 'Intro',
      title: 'windows flow into page - test',
    },
    {
      contentTypeUid: 'journeys_page_timeline',
      backButtonText: 'Volver',
      isStandalonePage: false,
      nextButtonText: 'Continuar',
      pageTitle: '',
      stepperTitleDesktop: 'Personaliza tu ventana',
      stepperTitleMobile: 'Elige tu ventana',
      timelines: [
        {
          optionalTitle: 'VENTANAS A MEDIDA',
          title: 'Selecciona el tipo de producto que necesitas',
          viewType: 'List',
          facetAttributeName: 'Producto',
          label: 'Producto:',
          defaultImageDesktop: {
            contentType: 'image/jpeg',
            filename: 'default_image_desktop.jpeg',
            title: 'default_image_desktop.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt5d659a1f43c9585f/658c09261c5d7c4fe50f1c34/default_image_desktop.jpeg',
          },
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Ventana',
              label: 'Ventana',
              description: '',
              imageDesktopMain: {
                contentType: 'image/jpeg',
                filename: 'desktop-producto-1.jpeg',
                title: 'desktop-producto-1.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltb4fb2d462768d17b/658c0950b05b9e77bad7201d/desktop-producto-1.jpeg',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-producto-1.jpeg',
                title: 'thumb-producto-1.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt7c6bc35c95d257db/658c0975a8ee430ec3198860/thumb-producto-1.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Ventanal',
              label: 'Ventanal',
              description: '',
              imageDesktopMain: {
                contentType: 'image/jpeg',
                filename: 'desktop-producto-2.jpeg',
                title: 'desktop-producto-2.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt648793c1abe4e1f3/658c096e71a55dd94dcd2c69/desktop-producto-2.jpeg',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-producto-2.jpeg',
                title: 'thumb-producto-2.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt34dc806628a8b696/658c097533550e9568d5b7e2/thumb-producto-2.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Selecciona el material',
          viewType: 'Grid',
          facetAttributeName: 'Material',
          label: 'Material:',
          defaultImageDesktop: {
            contentType: 'image/jpeg',
            filename: 'default-desktop.jpeg',
            title: 'default-desktop.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt5630b9b3c02c3cbc/658d513fbe5d2684d3658ba5/default-desktop.jpeg',
          },
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'PVC Europeo',
              label: 'PVC Europeo',
              description: '',
              imageDesktopMain: {
                contentType: 'image/png',
                filename: 'main-desktop-1.png',
                title: 'main-desktop-1.png',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltddb7f185b32bc0cb/658d514090dcf21fbc646737/main-desktop-1.png',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-desktop-1.jpeg',
                title: 'thumb-desktop-1.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt147802b6b78eaf1e/658c68912f46f7645c823c37/thumb-desktop-1.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'PVC Americano',
              label: 'PVC Americano',
              description: '',
              imageDesktopMain: {
                contentType: 'image/png',
                filename: '06_pvc_americano.png',
                title: 'main-desktop-2',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltfe02de8cee02ae4a/658d7266254eff7866746273/06_pvc_americano.png',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-2.jpeg',
                title: 'thumb-2.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt522050e141482e4b/658d513f1a8166149bb09786/thumb-2.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Aluminio Next',
              label: 'Aluminio Next',
              description: '',
              imageDesktopMain: {
                contentType: 'image/jpeg',
                filename: 'main-desktop-3.jpeg',
                title: 'main-desktop-3.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt88f594705cf13df8/658d5140b6f9247a98be1367/main-desktop-3.jpeg',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-3.jpeg',
                title: 'thumb-3.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltaf35eb3db30749cc/658d513f14672fd77d6f8ff8/thumb-3.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Aluminio Select',
              label: 'Aluminio Select',
              description: '',
              imageDesktopMain: {
                contentType: 'image/jpeg',
                filename: '04_aluminio_select.jpg',
                title: 'main-desktop-4',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltaadf971f51237c21/658da803b05b9e06fdd724d0/04_aluminio_select.jpg',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-4.jpeg',
                title: 'thumb-4.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt2fa9c3434c2b65f2/658d513f0543c5d68f8f1a5a/thumb-4.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Elige el sistema de apertura',
          viewType: 'Grid',
          facetAttributeName: 'Apertura',
          label: 'Apertura:',
          defaultImageDesktop: {
            contentType: 'image/jpeg',
            filename: 'default-desktop.jpeg',
            title: 'default-desktop.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt5630b9b3c02c3cbc/658d513fbe5d2684d3658ba5/default-desktop.jpeg',
          },
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Abatir',
              label: 'Abatir',
              description: '',
              imageDesktopMain: {
                contentType: 'image/png',
                filename: '14_pvc_europeo_abatir.png',
                title: 'main-desktop-1-14_pvc_europeo_abatir.png',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt65a2fa9d07418233/658dab13c3fb27866b19d3b4/14_pvc_europeo_abatir.png',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-1.jpeg',
                title: 'thumb-1.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blta0287f24b4c303eb/658d5469c3fb276b0f19d1fe/thumb-1.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Corredera dos hojas',
              label: 'Corredera dos hojas',
              description: '',
              imageDesktopMain: {
                contentType: 'image/jpeg',
                filename: '08_PVC_europeo_corredera_dos_hojas.jpg',
                title: 'main-desktop-2-PVC_europeo_corredera_dos_hojas.jpg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt86de7453c39fff86/658daabc4dad153c1ee550c5/08_PVC_europeo_corredera_dos_hojas.jpg',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-2.jpeg',
                title: 'thumb-2.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt676b1feaaf07077c/658d5469b782f060045849f6/thumb-2.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Fijo',
              label: 'Fijo',
              description: '',
              imageDesktopMain: {
                contentType: 'image/png',
                filename: '16_PVC_Europeo_fijo.png',
                title: 'desktop-3-PVC_Europeo_fijo.png',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt0f8835536fb25e58/658da969b6f9240791be1501/16_PVC_Europeo_fijo.png',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-3.jpeg',
                title: 'thumb-3.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltfa53f472805c953a/658d5469dc766299477ea331/thumb-3.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Proyectante',
              label: 'Proyectante',
              description: '',
              imageDesktopMain: {
                contentType: 'image/png',
                filename: '15_PVC_Euro_proproyectante_.png',
                title: 'main-desktop-4-15_PVC_Euro_proproyectante_.png',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blteeab3416669e944c/658dab40a2c41f8579db0807/15_PVC_Euro_proproyectante_.png',
              },
              imageDesktopSmall: {
                contentType: 'image/jpeg',
                filename: 'thumb-4.jpeg',
                title: 'thumb-4.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt9fd05c984139cd7b/658d54693ea36171cd575ba2/thumb-4.jpeg',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Elige la movilidad',
          viewType: 'List',
          facetAttributeName: 'Movilidad',
          label: 'Movilidad:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Izquierda',
              label: 'Izquierda',
              description:
                'Hay muchas variaciones de los pasajes de Lorem Ipsum disponibles.',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Derecha',
              label: 'Derecha',
              description:
                'Hay muchas variaciones de los pasajes de Lorem Ipsum disponibles.',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Selecciona el color',
          viewType: 'Grid',
          facetAttributeName: 'Color',
          label: 'Color:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Blanco',
              label: 'Blanco',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Nogal',
              label: 'Nogal',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Mate',
              label: 'Mate',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Titanio',
              label: 'Titanio',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Elige el tipo de cristal',
          viewType: 'Grid',
          facetAttributeName: 'Cristal',
          label: 'Cristal:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Monolítico',
              label: 'Monolítico',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Termopanel',
              label: 'Termopanel',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
      ],
      title: 'windows flow page timeline - test',
    },
    {
      contentTypeUid: 'journeys_page_measurement',
      backButtonText: 'Volver',
      imageDesktop: {
        contentType: 'image/jpeg',
        filename: 'windows_step_2_image.jpeg',
        title: 'windows_step_2_image.jpeg',
        url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blte6e01d077a7bd287/653f4cbd0ae856001b131b8c/windows_step_2_image.jpeg',
      },
      inputFields: [
        {
          type: 'number',
          labelIcon: {
            contentType: 'image/png',
            filename: 'windows_flow_step_2_label_1.png',
            title: 'windows_flow_step_2_label_1.png',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt404fe6edf840bf34/653f4c79c2b34d001be73012/windows_flow_step_2_label_1.png',
          },
          label: 'Ancho (cm)',
          placeholderValue: 'Ej. 280',
          attributeName: 'ancho',
        },
        {
          type: 'number',
          labelIcon: {
            contentType: 'image/jpeg',
            filename: 'windows_flow_step_2_label_2.jpeg',
            title: 'windows_flow_step_2_label_2.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltf1d3a4d23761b692/653f4c9b1680320407c39f88/windows_flow_step_2_label_2.jpeg',
          },
          label: 'Alto (cm)',
          placeholderValue: 'Ej. 180',
          attributeName: 'alto',
        },
      ],
      isStandalonePage: false,
      nextButtonText: 'Continuar',
      noteDescription:
        'Ingrese las dimensiones considerando el marco. Si hay un rasgo o vano libre, reste 5 mm tanto en el ancho como en el alto de esa medida. Mida en 3 puntos distintos y proporcione la menor medida obtenida.',
      noteTitle: 'Cómo medir tu ventana',
      pageTitle: 'Ingresa las medidas de tu ventana',
      stepperTitleDesktop: 'Ingresa las medidas',
      stepperTitleMobile: 'Medidas',
      title: 'windows flow measurement page',
    },
    {
      contentTypeUid: 'journeys_page_product_details',
      backButtonText: 'Volver',
      installationConditions:
        '<ol><li>Para una correcta instalación de cualquier ventana o ventanal, es indispensable tener el Vano nivelado y aplomado. Además debe estar libre o con desinstalación de ventanas existente de aluminio o PVC (no se reparan vanos). Por favor considerar que el Lugar debe ser accesible para llegar con el producto.</li><li>En el valor de la instalación se Incluyen materiales, instalación de marco, de hojas, regulaciones y aplicación de sellos de silicona neutra exterior e interior. Solo se consideran instalaciones en lugares de 1 o 2 pisos.</li><li>Para Cualquier condición fuera de lo mencionado se puede agrega como valor adicional una vez que se realice la visita de factibilidad técnica y rectificación de medidas.</li></ol>',
      installationDescription:
        'Te contactaremos en 48 hrs. hábiles para agendar una visita y rectificar las medidas.',
      installationTitle: 'Quiero agregar la instalación de las ventanas',
      isStandalonePage: false,
      nextButtonText: 'Continuar',
      noInstallationDescription:
        'Utilizaremos las medidas ingresadas anteriormente.',
      noInstallationTitle: 'No quiero agregar instalación',
      pageTitle:
        '<p>Agrega instalación</p><p>Las opciones que estás viendo son <strong>específicas para la comuna que seleccionaste</strong>.</p>',
      stepperTitleDesktop: 'Agrega o no instalación',
      stepperTitleMobile: 'Instalación',
      title: 'windows flow installation and product details page',
    },
    {
      contentTypeUid: 'journeys_page_addtocart',
      backButtonText: 'Volver',
      isStandalonePage: false,
      nextButtonText: 'Agregar al carro',
      pageTitle: 'Resumen y agregar al carro',
      stepperTitleDesktop: 'Revisa y agrega al carro',
      stepperTitleMobile: 'Carro',
      title: 'Windows Flow add to cart page',
      cartUrl: 'https://www.sodimac.cl/sodimac-cl/cart',
      isCartPageOpenInNewTab: false,
      productPriceLabel: 'Ventana',
    },
  ],
  title: 'Journeys Windows Flow - test',
};
